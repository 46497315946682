body {
  background-color: #eeebeb;
}
.App {
  position: relative;
  padding-top: 80px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.background {
  position: fixed;
  /* background-color: #333; */
  top: 0px;
  left: 0;
  width: 100%;
  height: 500px;
  z-index: -1;
  overflow: hidden;
}
.background video {
  width: 100%;
}

.AppHeader {
  color: #fff;
  padding-right: 56px;
  padding-left: 56px;
  background-color: rgba(0, 0, 0, 0.8);
  height: 80px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
.AppHeader a {
  color: #fff;
  margin-left: 15px;
  text-decoration: none;
}
.logo {
  font-size: 40px;
  font-weight: bold;
  position: absolute;
  left: 56px;
  top: 10px;
}
.links {
  margin-left: 80px;
  padding-top: 25px;
}

.controlBar {
  float: right;
  color: #fff;
  line-height: 80px;
}

.controlBar span {
  cursor: pointer;
}

.button {
  background-color: #07c;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 5px 10px;
  margin-left: 25px;
}

.Sidebar {
  background: #111;
  position: fixed;
  left: 0;
  top: 0;
  width: 280px;
  height: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
}

.sidebarLogo {
  position: relative;
  width: 50%;
  margin-bottom: 120px;
}

.sectionHeader {
  color: #ddd;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 20px;
  /* text-indent: 15px; */
}
.assetList {
  list-style: none;
  color: #fff;
  margin: 0;
  padding: 0;
}
.assetList li {
  height: 40px;
  line-height: 50px;
}
.symbolIcon {
  width: 22px;
  margin-right: 10px;
  vertical-align: middle;
  margin-top: -3px;
}

.DashboardContainer {
  margin-left: 280px;
  padding: 0px 35px;
}
.DashboardContainer nav {
  margin-bottom: 30px;
}
.DashboardContainer nav a {
  color: #000;
  text-decoration: none;
}
.DashboardContainer header span {
  font-size: 30px;
}

.walletButton {
  border: none;
  background-color: #07c;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 10px 15px;
  margin-left: 25px;
  cursor: pointer;
}

.walletBlock {
  float: right;
}

.accountStatus {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  cursor: pointer;
  background-color: #607bee;
  border: 1px solid #607bee;
  border-radius: 20px;
}
.accountStatus span {
  font-size: 14px !important;
  color: #fff;
  height: 38px;
  line-height: 38px;
  margin-left: 15px;
}
.accountStatus .networkName {
  font-weight: bold;
}

.accountStatus .address {
  background-color: #fff;
  color: #607bee;
  padding: 0 10px;
  border-radius: 0 20px 20px 0;
}

.form {
  width: 500px;
  margin: 300px auto 0;
}
.form p {
  color: #999;
}
.form input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ddd;
  height: 30px;
  margin: 15px 0;
  padding: 5px 15px;
}
.form button {
  border: none;
  background-color: #dedede;
  color: #000;
  border-radius: 5px;
  padding: 15px 25px;
  margin: 0;
}
.form a {
  color: #07c;
  
}
.link {
  color: #07c;
  font-size: 18px;
  text-decoration: none;
}